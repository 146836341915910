.svgIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
}
.svgIcon:global(.small) svg {
  width: 16px;
  height: 16px;
}
.svgIcon:global(.medium) svg {
  width: 22px;
  height: 22px;
}
.svgIcon:global(.large) svg {
  width: 24px;
  height: 24px;
}
.svgIcon:global(.extra-large) svg {
  width: 28px;
  height: 28px;
}