/* Bulma Utilities */
@media screen and (min-width: 1024px) {
  .calendarCardContent {
    margin-top: 2.5em;
    margin-bottom: 3em;
  }
}
.calendarCardContent svg {
  color: #3489aa;
}
.calendarCardContent .hiddenSelect {
  visibility: hidden;
}

.subtextSection {
  color: #d3771f;
  font-weight: bold;
  font-size: 24px;
  margin-left: 1em;
}
@media screen and (max-width: 768px) {
  .subtextSection {
    font-size: 18px;
    margin-left: 0em;
  }
}
@media screen and (min-width: 769px), print {
  .subtextSection {
    margin-left: 0em;
  }
}

.calendarSection h4 {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 0.5em;
}
.calendarSection p {
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 0.5em;
  color: #999999;
}
.calendarSection .boxDatePicker {
  padding-left: 0em;
  padding-right: 0em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.calendarSection .boxCustomRadioButtonGroup {
  padding-left: 0em;
  padding-right: 0em;
}

.controlCalendar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.controlCalendar button {
  border: none;
  background: transparent;
}

.calendarContent {
  display: flex;
}
.calendarContent div {
  padding-bottom: 0.2em;
  padding-top: 0.2em;
  text-align: center;
}
.calendarContent div h3 {
  color: #3489aa;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.calendarContent div .arrowSelect {
  color: #3489aa;
  font-size: 24px;
}
@media screen and (min-width: 1024px) {
  .calendarContent div .is-fullwidth {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (max-width: 768px) {
  .calendarContent div .is-fullwidth {
    padding-top: 0em;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
.calendarContent .arrowLeft,
.calendarContent .arrowRight {
  border: none;
  background: transparent;
}
.calendarContent .arrowLeft:hover,
.calendarContent .arrowRight:hover {
  opacity: 0.7;
}
.calendarContent .arrowLeft:active,
.calendarContent .arrowRight:active {
  opacity: 0.5;
  border: none;
}
.calendarContent .arrowLeft svg,
.calendarContent .arrowRight svg {
  font-size: 36px;
}

.reservationCard {
  margin-bottom: 1.5em;
  background-color: white !important;
  border: 1px solid #3b3b3b;
}
@media screen and (min-width: 1024px) {
  .reservationCard {
    margin-bottom: 2.5em;
  }
}

.summary h4 {
  font-weight: bold;
  margin-bottom: 2em;
}
.summary ul li + li {
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}
.summary ul li strong,
.summary ul li span {
  margin-left: 1em;
}
.summary > div:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}