/* Bulma Utilities */
.searchResults h2 {
  color: #999999;
  font-weight: bold;
  margin-right: 1em;
}
.searchResults :global(.column) {
  display: flex;
  align-items: center;
}
.searchResults :global(.column) small {
  color: #999999;
  font-size: 12px;
  margin-right: 0.5rem;
  font-weight: normal;
}
.searchResults :global(.column) label {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .searchResults :global(.column) {
    justify-content: flex-end;
  }
}

.searchGroup {
  width: 100%;
}