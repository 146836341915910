.icon_avatar_children {
  border-radius: 50px;
  object-fit: cover;
  width: 4.5rem;
  height: 4.5rem;
}

.icon-avatar {
  border-radius: 25px;
  object-fit: cover;
}

.default-avatar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
}
.default-avatar-wrapper .nickname-text {
  color: white;
  /* font-weight: bold; */
  font-size: 1.5rem;
  padding-bottom: 0.15rem;
}