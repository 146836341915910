/* Bulma Utilities */
.commentCard {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding-left: 2em !important;
  padding-right: 2em !important;
  background-color: white !important;
  border: 1px solid #3b3b3b;
}
@media screen and (max-width: 768px) {
  .commentCard {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}

.commentCardContent {
  padding: 0px;
}

.commentText p {
  font-weight: 600;
  font-size: 15px;
  text-align: justify;
}

.detailRanking {
  font-size: 18px;
  display: flex;
  align-items: center;
}
.detailRanking i {
  display: flex;
  align-items: center;
}
.detailRanking svg {
  color: #c4c4c4;
}
.detailRanking span {
  color: #c4c4c4;
  margin-left: 0.2em;
  font-size: 14px;
}

.activeStar svg {
  color: #3489aa;
}

.commentValoration {
  padding-top: 0px;
}
@media screen and (max-width: 768px) {
  .commentValoration span {
    font-size: 12px;
  }
  .commentValoration span .detailRanking {
    font-size: 12px;
  }
}

.avatarCard {
  transition: all 0.3s ease-in-out;
}
.avatarCard p {
  color: #3489aa;
  font-weight: bold;
  margin-left: 1em;
}
@media screen and (max-width: 768px) {
  .avatarCard p {
    margin-left: 0.5em;
    font-size: 14px;
  }
}
.avatarCard .detailRanking:hover {
  opacity: 0.7;
  cursor: pointer;
}
.avatarCard .detailRanking:active {
  opacity: 0.5;
}

.cardReactions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardReactions button {
  border: medium none;
}
.cardReactions button:hover svg {
  color: #d3771f;
}
.cardReactions button svg {
  color: #c4c4c4;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .cardReactions button svg {
    font-size: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .cardReactions button {
    border: medium none;
    margin: 0.5em;
  }
  .cardReactions button svg {
    font-size: 26px;
  }
}