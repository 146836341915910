/* Bulma Utilities */
.filterCard {
  padding: 0.7em;
}

.homeListCard {
  display: block;
}

.cardImage {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}
.cardImage img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cardHeading {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
@media screen and (max-width: 768px) {
  .cardHeading a {
    font-size: 16px;
  }
}

.cardText span {
  color: #999999;
  font-size: 14px;
}
.cardText p {
  font-weight: 600;
  font-size: 15px;
}

.available {
  background-color: #d3771f;
  color: white;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
  font-size: 14px;
  padding: 0 2em;
  border-radius: 0 0 0 2px;
  height: 28px;
  line-height: 28px;
}
@media screen and (max-width: 1023px) {
  .available {
    font-size: 12px;
    padding: 0 0.5em;
    letter-spacing: 1px;
  }
}