.svg {
  z-index: 1;
}
.svg path,
.svg polygon,
.svg rect,
.svg circle {
  fill: rgba(211, 119, 31, 0.5);
  stroke: #d3771f;
  stroke-width: 2px;
  cursor: pointer;
  transition: fill 0.3s ease;
}
.svg path:hover,
.svg polygon:hover,
.svg rect:hover,
.svg circle:hover {
  fill: #d3771f;
}
.svg path.marked,
.svg polygon.marked,
.svg rect.marked,
.svg circle.marked {
  fill: rgba(52, 137, 170, 0.85);
  stroke: #3489aa;
  stroke-width: 4px;
}
.svg path.occupy,
.svg polygon.occupy,
.svg rect.occupy,
.svg circle.occupy {
  fill: rgba(255, 0, 0, 0.85);
  stroke: #3489aa;
  stroke-width: 4px;
  pointer-events: none;
}

.tooltip {
  background-color: black;
  border-radius: 8px;
}
.tooltip p {
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 0 0.5em;
  line-height: 60px;
  margin: 0;
}