/* Bulma Utilities */
.boxDurationRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0em;
  padding-right: 0em;
  justify-content: flex-start;
}
@media screen and (max-width: 768px) {
  .boxDurationRow {
    justify-content: space-between;
  }
}
@media screen and (min-width: 769px), print {
  .boxDurationRow {
    justify-content: flex-start;
  }
}
.boxDurationRow button {
  border-color: #c4c4c4;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}
@media screen and (max-width: 768px) {
  .boxDurationRow button {
    margin-right: 0em;
  }
}
@media screen and (min-width: 1024px) {
  .boxDurationRow button {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
.boxDurationRow button:hover {
  background: #3489aa;
  color: white !important;
}
.boxDurationRow button:disabled {
  opacity: none !important;
  background: transparent;
  border-color: #999999;
  color: #999999 !important;
}
.boxDurationRow button:disabled:hover {
  color: #999999;
  opacity: none !important;
}

.boxDurationColumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .boxDurationColumn {
    padding-left: 0em;
    padding-right: 0em;
  }
}
.boxDurationColumn button {
  margin-bottom: 0.5em;
  border-color: #c4c4c4;
  width: 100%;
}
.boxDurationColumn button:hover {
  background: #3489aa;
  color: white !important;
}
.boxDurationColumn button:disabled {
  opacity: none !important;
  background: transparent;
  border-color: #999999;
  color: #999999 !important;
}
.boxDurationColumn button:disabled:hover {
  color: #999999;
  opacity: none !important;
}

.buttonSelected {
  background: #3489aa;
  color: white !important;
}