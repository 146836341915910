/* Bulma Utilities */
.venue {
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .venue {
    width: 165px;
  }
}
.venue img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.description {
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  .description h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }
}
.description div:first-child > span,
.description div:first-child > small {
  display: block;
  margin-top: 0.5em;
}
.description small {
  color: #999999;
}

.actions {
  margin-top: 0.5em;
  display: flex;
  justify-content: space-between;
}
.actions > div {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
}
.actions > div:first-child > i + i {
  margin-left: 0.5em;
}
.actions > div:last-child > * + * {
  margin-left: 1em;
}