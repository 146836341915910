/* Bulma Utilities */
.sectionData {
  padding-top: 0em;
  padding-bottom: 0em;
}

.infoProfile .imageProfile {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 1.5rem !important;
}

.column_profile {
  padding: 0rem !important;
  margin: auto !important;
}

.column_image {
  text-align: center;
}

.image_reservation {
  border-radius: 5px !important;
}

.infoUserMeta {
  display: flex;
  flex-flow: column wrap;
  margin-left: 30px;
}
.infoUserMeta h2 {
  font-weight: bold;
  font-size: 24px;
}

.headerSection h2 {
  font-weight: bold;
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .headerSection h2 {
    font-size: 18px;
  }
}

.editProfile {
  display: flex !important;
  justify-content: flex-end !important;
}
.editProfile button {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .columnData {
    padding-left: 0em;
    padding-right: 0em;
  }
}
@media screen and (min-width: 1024px) {
  .columnData {
    border-left: 2px solid #999999;
  }
}

@media screen and (min-width: 1024px) {
  .columnCard {
    margin-left: 1.5em;
  }
}

.icon_delete_favorite {
  font-size: 40px;
}

.input_file_photo {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file_input {
  justify-content: center !important;
}

.label_signup {
  display: block;
  position: relative;
  width: 6rem;
  height: 6rem;
  border-radius: 5rem;
  background-color: #cfcfc6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  margin-bottom: 1rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.file_name {
  position: absolute;
  bottom: -35px;
  left: 10px;
  font-size: 0.85rem;
  color: #555;
}

.card {
  max-width: 90% !important;
}

.cancelReserve {
  height: 35px !important;
  font-size: 1rem !important;
  margin-top: 50px;
}