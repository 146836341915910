/* Bulma Utilities */
.hero {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2em 0;
}
@media screen and (min-width: 1024px) {
  .hero:global(.is-medium) {
    height: 408px;
  }
  .hero:global(.is-small) {
    height: 320px;
  }
}
@media screen and (max-width: 768px) {
  .hero:global(.is-small) {
    height: 192px;
  }
}
.hero__content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero__content > div {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .hero__content:global(.container) {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .hero__content:global(.container) {
    margin: 0 1em;
  }
}