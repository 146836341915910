/* Bulma Utilities */
.asideList {
  margin-top: 2.5em;
}
.asideList h4 {
  margin-bottom: 1em;
  line-height: 22px;
  color: #3489aa;
}
@media screen and (min-width: 1024px) {
  .asideList h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
  }
}
.asideList a {
  font-weight: bold;
  font-size: 15px;
  color: #3b3b3b;
  text-decoration: underline;
}
.asideList a:hover {
  color: #d3771f;
}
.asideList small {
  display: block;
  font-size: 12px;
  margin-top: 0.5em;
  color: #999999;
}

.asideListItem {
  display: flex;
}
.asideListItem + .asideListItem {
  margin-top: 0.8em;
}
.asideListItem figure {
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
}
.asideListItem figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.asideListItem div {
  flex: 1 1 auto;
}

.sectionAside {
  height: 82vh;
}