.anchorList {
  list-style: none outside none !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  padding: 0;
  display: flex;
  margin-bottom: 2em;
}
.anchorList li {
  text-transform: uppercase;
}
.anchorList li + li {
  margin-left: 1em;
}
.anchorList li a {
  font-weight: 600;
  font-size: 14px;
  color: #d3771f;
  text-decoration: underline;
}