.datePicker {
  padding-left: 0em;
  padding-right: 0em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.datePicker input {
  border-color: #c4c4c4;
  color: #3489aa;
  background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 90% 50% no-repeat;
}
.datePicker input:hover {
  cursor: pointer;
}

@media (max-width: 375px) {
  .modalPicker {
    position: absolute;
    left: -27px !important;
  }
}