/* Bulma Utilities */
.image {
  width: 100%;
  height: 0;
  padding-bottom: 72.6%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.image img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
}

.details {
  padding: 1em 0;
}
.details h3 {
  font-size: 16px;
  font-weight: bold;
}
@media screen and (min-width: 1024px) {
  .details h3 {
    font-size: 18px;
  }
}
.details small {
  display: inline-block;
  color: #999999;
  font-size: 12px;
  margin-top: 1em;
}
@media screen and (min-width: 1024px) {
  .details small {
    font-size: 14px;
  }
}