.fieldset {
  margin-top: 1em;
}
.fieldset legend {
  font-size: 12px;
  margin-bottom: 1em;
}
.fieldset__inputRange {
  margin-top: 19px !important;
}
.fieldset__inputRange div div:first-child {
  background-color: #d3771f !important;
}
.fieldset__inputRange div div:last-child {
  background-color: #d3771f !important;
  color: #d3771f !important;
  border: #d3771f !important;
}