.loadingBlock {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  min-height: 60px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 100%;
}

.loadingBlock > div {
  color: #d3771f !important;
}

.loadingMsg {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.loadingContainer {
  display: grid;
  place-items: center;
  height: 70vh;
}