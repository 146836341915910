/* Bulma Utilities */
.filterTitle h2 {
  color: #3489aa;
  font-size: 24px;
  margin-bottom: 0;
  padding-right: 1em;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .filterTitle h2 {
    border-right: 1px solid #3489aa;
  }
}
@media screen and (max-width: 768px) {
  .filterTitle h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0.5em 0;
  }
}

.column:global(.column) {
  display: flex;
  align-items: center;
}

.filterLink {
  text-align: center;
}
.filterLink a {
  color: #3489aa;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}