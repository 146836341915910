/* Bulma Utilities */
@media screen and (max-width: 768px) {
  .boxPhoto div {
    width: 3em !important;
  }
}

.descriptionHeader h1,
.descriptionHeaderWithPhoto h1 {
  font-weight: bold;
  font-size: 28px;
}
.descriptionHeader h2,
.descriptionHeaderWithPhoto h2 {
  color: #3489aa;
  font-weight: bold;
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .descriptionHeader h2,
  .descriptionHeaderWithPhoto h2 {
    font-size: 18px;
  }
}
.descriptionHeader h3,
.descriptionHeaderWithPhoto h3 {
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}
.descriptionHeader h4,
.descriptionHeaderWithPhoto h4 {
  padding-top: 10px;
  font-weight: 100;
  font-size: 14px;
}
.descriptionHeader p,
.descriptionHeaderWithPhoto p {
  font-size: 12px;
  color: #999999;
}

.descriptionHeaderWithPhoto {
  display: flex;
}
.descriptionHeaderWithPhoto h3 {
  margin-left: 1em;
}

.boxDataColumn,
boxDataColumnWithPhoto {
  padding-top: 0em;
}

@media screen and (min-width: 1024px) {
  .boxDataColumnWithPhoto {
    position: absolute;
    left: 12.5em;
    top: 3em;
  }
}

.columnIsNotMenu {
  padding-top: 0px;
  padding-bottom: 0px;
  padding: 0em 0em 0em 0em !important;
}
@media screen and (min-width: 1024px) {
  .columnIsNotMenu {
    padding-left: 1.5em;
  }
}

.columnIsMenu {
  padding: 0em 0em 1em 0em;
}

.hasNotPaddingBottom {
  padding: 0em;
}

.formPartHeader {
  display: flex;
  align-items: center;
}
.formPartHeader svg {
  font-size: 20px;
  color: #c4c4c4;
  margin-right: 1em;
}
@media screen and (max-width: 768px) {
  .formPartHeader svg {
    margin-right: 0.5em;
    font-size: 20px;
  }
}
.formPartHeader h3 {
  color: #3b3b3b;
  font-size: 14px;
}
.formPartHeader h4 {
  font-weight: bold;
  font-size: 16px;
}
.formPartHeader div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formPartBody {
  padding-top: 0px;
  padding-left: 4em;
}
.formPartBody p {
  font-weight: 500;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .formPartBody {
    padding-left: 3.2em;
  }
}
@media screen and (min-width: 769px), print {
  .formPartBody {
    padding-left: 3.4em;
  }
}
@media screen and (min-width: 1024px) {
  .formPartBody {
    padding-left: 4em;
  }
}

.formPartServices {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.formPartServices div {
  padding-right: 2em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.formPartServices div p {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.formPartServices div svg {
  font-size: 22px;
  margin-right: 1em;
  color: #c4c4c4;
}
@media screen and (max-width: 768px) {
  .formPartServices {
    flex-direction: column;
    text-align: left;
  }
  .formPartServices div {
    padding-right: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .formPartServices div svg {
    font-size: 28px;
  }
  .formPartServices div p {
    width: 100%;
    font-size: 14px;
    text-align: left;
  }
}

.descriptionButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .descriptionButton h4 {
    margin-bottom: 5px;
  }
  .descriptionButton button {
    display: flex;
    width: 100%;
    margin-top: 5px;
  }
}
.descriptionButton button {
  font-size: 18px;
  font-weight: bold;
}