.cardRanking {
  font-size: 18px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
}
.cardRanking i {
  display: flex;
  align-items: center;
}
.cardRanking svg {
  color: rgba(153, 153, 153, 0.4);
}
.cardRanking span {
  color: #999999;
  margin: 0 0 0 0.2em;
  font-size: 14px;
}

.activeStar svg {
  color: #3489aa;
}