.mainMenu:global(.column) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainMenu__bar {
  margin-left: 0;
  margin-right: 0 !important;
}
.mainMenu__nav {
  margin: 0 auto;
}
.mainMenu__nav > * + * {
  margin-left: 0.8em;
}
.mainMenu__nav a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.userMenu {
  display: flex !important;
  box-shadow: none !important;
}
.userMenu:global(.column) {
  display: flex;
  align-items: center;
}
.userMenu__container {
  margin-left: auto;
  margin-right: 0;
}
.userMenu svg {
  width: 28px;
  height: 28px;
  margin-right: 0.5em;
}
.userMenu span {
  font-size: 14px;
}
.userMenu span,
.userMenu a {
  font-weight: 600;
}

.search {
  padding: 0;
  width: 40px;
  height: 40px;
}
.search svg {
  width: 20px;
  height: 20px;
}

.hasSvg {
  display: block !important;
  box-shadow: none !important;
}
.hasSvg svg {
  width: 16px;
  height: 16px;
}

.avatarProfile {
  padding-right: 5px;
}

.profile {
  padding-left: 10px;
}

.imgage_logo {
  max-height: 2.2rem !important;
}