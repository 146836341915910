.authFormWrapper {
  flex-direction: column;
  align-items: center;
}

.infoWrapper {
  min-height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.nav {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #3489aa;
  gap: 1em;
}
.nav button {
  border: medium none;
  padding: 0.5em 1.5em;
  font-size: 1em;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  outline: medium none;
}
.nav button[aria-selected=true] {
  font-weight: 600;
  color: #d3771f;
}
.nav button[aria-selected=true]:after {
  content: "";
  width: 0;
  height: 0;
  border-width: 5px 5px 0 5px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  border-style: solid;
  border-color: #3489aa transparent transparent transparent;
  transform: translateX(-50%);
}

.input_file_photo {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file_input {
  justify-content: center !important;
}

.label_signup {
  display: block;
  position: relative;
  width: 6rem;
  height: 6rem;
  border-radius: 5rem;
  background-color: #cfcfc6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  margin-bottom: 1rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.file_name {
  position: absolute;
  bottom: -35px;
  left: 10px;
  font-size: 0.85rem;
  color: #555;
}