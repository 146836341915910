.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: black;
  z-index: 10;
  cursor: pointer;
}

.modal {
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  min-width: 300px;
  max-width: 600px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 11;
  background-color: white;
  transform: translateX(-50%) translateY(-50%);
}
.modalHead {
  position: relative;
  height: 22px;
}
.modalBody {
  padding: 0.5em 1em 1em;
}
.modalActions {
  height: 32px;
}

.close:global(.button) {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  border: medium none;
  width: auto;
  height: auto;
}

.contentTitle {
  color: #3489aa;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}