/* Bulma Utilities */
.item + .item {
  margin-top: 1em;
}
@media screen and (max-width: 768px) {
  .item + .item {
    border-top: 1px solid #eaeaea;
    padding-top: 1em;
  }
}

.image {
  height: 0;
  overflow: hidden;
  position: relative;
  padding-bottom: 53.93%;
  width: 100%;
  border-radius: 8px;
}
.image img {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.info h3 {
  font-size: 24px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .info h3 {
    font-size: 18px;
  }
}
.info span {
  font-size: 18px;
  color: #999999;
}
@media screen and (max-width: 768px) {
  .info span {
    font-size: 16px;
  }
}
.info p {
  margin-top: 2em;
  line-height: 20px;
  font-size: 16px;
}
.info a {
  color: #d3771f;
  text-decoration: underline;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}
@media screen and (min-width: 1024px) {
  .info a {
    margin-top: 1rem;
  }
}

.actions {
  display: flex;
}
@media screen and (max-width: 768px) {
  .actions ul {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .actions ul li + li {
    margin-left: 1em;
  }
}
@media screen and (min-width: 1024px) {
  .actions li + li {
    margin-top: 1em;
  }
}
@media screen and (max-width: 1023px) {
  .actions {
    padding: 0.5em 0 0;
  }
}
.actions a {
  border: medium none;
  padding: 0;
  padding: 4px;
}
.actions a svg {
  width: 28px;
  height: 28px;
  color: #c4c4c4;
}
.actions a:hover svg {
  color: #d3771f;
}