/* Bulma Utilities */
.nameSpace {
  padding-bottom: 0px;
}
.nameSpace span {
  color: #c4c4c4;
  font-size: 14px;
}
.nameSpace h1 {
  font-weight: bold;
  font-size: 32px;
  position: relative;
  padding-bottom: 0px;
}
@media screen and (max-width: 768px) {
  .nameSpace h1 {
    font-size: 24px;
  }
}

.detailActions {
  display: flex;
  align-items: center;
}

.detailColumn {
  padding-bottom: 0px;
  padding-top: 1em;
}

.detailFavorite {
  transition: all 0.3s ease-in-out;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  color: #c4c4c4;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .detailFavorite {
    justify-content: flex-end;
  }
}
@media screen and (min-width: 1024px) {
  .detailFavorite svg {
    position: absolute;
    left: 6em;
  }
}
.detailFavorite:hover {
  cursor: pointer;
  opacity: 0.9;
}
.detailFavorite:active {
  color: #d3771f;
  opacity: 0.7;
}

.detailButton {
  display: flex;
  justify-content: flex-end;
  padding-top: 0px;
  padding-bottom: 0px;
}
@media screen and (min-width: 769px), print {
  .detailButton {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 768px) {
  .detailButton {
    justify-content: center;
    margin-bottom: 0em;
  }
}

.detailTextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .detailTextButton {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .detailText {
    margin-top: 2em;
  }
}
.detailText p {
  font-weight: 600;
  font-size: 15px;
}

.subtextSection {
  color: #3489aa;
  font-weight: bold;
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .subtextSection {
    font-size: 18px;
  }
}

.bookButtonContainer:global(.column) {
  display: grid;
  place-items: center;
}