.infoWindow {
  padding: 0.5em;
}
.infoWindow a {
  color: #d3771f;
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
}
.infoWindow p {
  color: #3b3b3b;
  margin-top: 1em;
  font-size: 14px;
  font-weight: 500;
}