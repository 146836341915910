.blueprint {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  width: 100%;
  min-height: 540px;
}

.zoomInner {
  position: relative;
  display: flex;
  flex-direction: column;
}
.zoomInner > svg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 10;
}