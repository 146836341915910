.active {
  border-color: #d3771f !important;
}

.tabbedFilter {
  list-style: none outside none !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  padding: 0;
  display: flex;
}
.tabbedFilter li + li {
  margin-top: 0;
  margin-left: 0.5em;
}
.tabbedFilter li button {
  font-weight: 600;
  font-size: 14px !important;
  padding: 0.1em 0.4em;
  height: 24px;
}
.tabbedFilter li button:not(:hover) {
  border: 1px solid transparent;
}