.homeBooking h1:after {
  content: "";
  background-color: #d3771f;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  position: absolute;
}

.bookingForm {
  padding-top: 1em;
}
.bookingForm :global(.column) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.bookingField {
  width: 100%;
  margin-bottom: 19px;
}
.bookingField select {
  width: 100%;
}

.datePickerActions {
  clear: both;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}
.datePickerActions button {
  font-size: 14px !important;
  font-weight: 600;
}
.datePickerActions button + button {
  margin-top: 0.5em;
}

.textPosition {
  position: absolute;
}

.bookingSearch {
  width: 100%;
  position: relative;
  top: -19px;
}